import React from "react"
import ProgressBar from "./ProgressBar"
import Tools from "./Tools"


const FooterBar = () => {

  return (<>
    <ProgressBar />
    <Tools />
  </>)
}

export default FooterBar